
import { TypedVue } from "@/store/types";
import { Project, Organization, V2Benchmark } from "@/store/admin/adminTypes";
import { Assessment } from "@/store/internal/internalTypes";
import { Component, Watch } from "vue-property-decorator";
import ProjectDropdown from "@/components/input/ProjectDropdown.vue";
import adminService from "@/services/adminService";
import { internalAPI } from "@/lib/s3PlatformApi";
import fileDownload from "js-file-download";
import StateBadge from "@/components/platform/StateBadgeComponent.vue";

@Component({
  components: {
    ProjectDropdown,
    StateBadge,
  },
})
export default class AssessmentList extends TypedVue {
  assessments: Assessment[] = [];
  modalActive = false;
  organizations: Organization[] = [];
  projects: Project[] = [];
  selectedProjects: Project[] = [];
  assessmentProjects: Project[] = [];
  rootBenchmarks: V2Benchmark[] = [];
  selectedAssessment: Assessment | null = null;
  candidateName: string | null = null;
  candidateEmail: string | null = null;
  dismissCountDown = 0;
  bulkCreateFile: File | null = null;
  modalButtonsEnabled = true;
  loadingComplete = false;
  assessmentsLoading = true;

  currentPage = 1;
  perPage = 20;
  totalRecords = 0;
  sort = "-date_created";
  searchText = "";
  orgFilter = "";
  projectFilter = "";

  showStateFilter = false;
  stateFilter: string[] = [];
  stateOptions = [
    { name: "Incomplete", value: "incomplete" },
    { name: "In Progress", value: "in-progress" },
    { name: "Pending", value: "complete,scoring_complete" },
    { name: "Complete", value: "report-generated" },
  ];

  get fields() {
    return [
      {
        key: "identifier",
        label: "ID",
        sortable: true,
        sortKey: "identifier",
      },
      { key: "candidate.name", label: "Name", sortable: true },
      { key: "candidate.email", label: "Email", sortable: true },
      {
        key: "orgId",
        label: "Organization",
        sortable: true,
        sortKey: "org_id",
        formatter: (value: string) => {
          return this.organizations.find((org) => org.id === value)?.displayName || "";
        },
      },
      {
        key: "project",
        sortable: true,
        sortKey: "project.name",
        label: "Project",
      },
      {
        key: "rootBenchmark",
        sortable: true,
        sortKey: "project.root_benchmark_id",
        label: "Root Benchmark",
      },
      {
        key: "state",
        sortable: true,
      },
      { key: "attempts", sortable: true },
      {
        key: "numberOfResponses",
        label: "Q's Answered",
        formatter: (value: number, _: string, row: Assessment) => {
          if (row.state === "incomplete") return "-";
          return value;
        },
      },
      {
        key: "expirationDate",
        label: "Expires On",
        sortable: true,
        sortKey: "expiration_date",
        formatter: (value: string, _: string, row: Assessment) => {
          if (!value || this.isComplete(row)) return "-";
          return new Date(value).toDateString();
        },
      },
      {
        key: "dateCreated",
        label: "Created On",
        sortable: true,
        sortKey: "date_created",
        formatter: function (value: string): string {
          if (!value) return "-";
          return new Date(value).toDateString();
        },
      },
      {
        key: "lastAttemptDate",
        label: "Last Attempted On",
        sortable: true,
        sortKey: "last_attempt_date",
        formatter: function (value: string): string {
          if (!value) return "-";
          return new Date(value).toDateString();
        },
      },
      {
        key: "dateCompleted",
        label: "Completed On",
        sortable: true,
        sortKey: "date_completed",
        formatter: function (value: string): string {
          if (!value) return "-";
          return new Date(value).toDateString();
        },
      },
      {
        key: "reportViewed",
        label: "Opened Report?",
        formatter: (value: string | null, _: string, row: Assessment) => {
          if (row.state !== "report-generated") {
            return "-";
          }
          if (value) return "Yes";
          return "No";
        },
      },
      {
        key: "isCopy",
        label: "Is Copy?",
        formatter: function (value: boolean): string {
          if (value) return "Yes";
          return "No";
        },
      },
      "actions",
    ];
  }

  get projectChoice(): Project[] {
    return this.selectedProjects;
  }

  set projectChoice(projects: Project[]) {
    this.selectedProjects = projects;
  }

  get projectOptions(): Project[] {
    if (this.selectedAssessment === null) return [];

    return this.projects.filter((p) => {
      return p.organization === this.selectedAssessment?.orgId;
    });
  }

  get projectFilters() {
    return [{ value: "", text: "ALL" }].concat(
      this.projects
        .filter((p) => {
          if (this.orgFilter === "") return true;
          return p.organization === this.orgFilter;
        })
        .map((p) => {
          return { value: p.projectId, text: p.name + (p.projectType ? " - " + p.projectType : "") };
        }),
    );
  }

  get orgFilters() {
    return [{ value: "", text: "ALL" }].concat(
      this.organizations.map((org) => {
        return { value: org.id, text: org.displayName };
      }),
    );
  }

  @Watch("currentPage")
  onPageChanged(): void {
    this.getAssessments().catch((err) => console.error(err));
  }

  @Watch("searchText")
  onSearchChanged(): void {
    this.getAssessments().catch((err) => console.error(err));
  }

  @Watch("stateFilter")
  onStateFilterChanged(): void {
    this.getAssessments().catch((err) => console.error(err));
  }

  @Watch("projectFilter")
  onProjectFilterChanged(): void {
    this.getAssessments().catch((err) => console.error(err));
  }

  @Watch("orgFilter")
  onOrgFilterChanged(): void {
    this.projectFilter = "";
    this.getAssessments().catch((err) => console.error(err));
  }

  @Watch("perPage")
  onPerPageChanged(): void {
    this.getAssessments().catch((err) => console.error(err));
  }

  async mounted(): Promise<void> {
    await this.getAssessments();
    await this.getOrganizations();
    await this.getProjects();
    await this.getRootBenchmarks();
    this.loadingComplete = true;
  }

  async deleteAssessment(): Promise<void> {
    this.modalButtonsEnabled = false;
    if (this.selectedAssessment && confirm("Are you sure you want to delete this simulation?")) {
      await adminService.deleteAssessment(this.selectedAssessment.identifier);
      await this.getAssessments();
      this.selectedAssessment = null;
      this.modalActive = false;
    }
    this.modalButtonsEnabled = true;
  }

  async editAssessment(): Promise<void> {
    const chosenProjects = this.selectedProjects;
    this.modalButtonsEnabled = false;
    if (confirm("Are you sure you want to edit this simulation?")) {
      const assessment = this.selectedAssessment;
      if (assessment !== null) {
        await adminService.updateAssessmentDetails(assessment.identifier, this.candidateName !== null ? this.candidateName : "", this.candidateEmail !== null ? this.candidateEmail : "");

        // Unassign assessment from projects as needed
        for (const assessmentProject of this.assessmentProjects) {
          const index = chosenProjects.findIndex((selectedProject: Project) => selectedProject.projectId === assessmentProject.projectId);
          if (index === -1) {
            await adminService.removeAssessmentFromProject(assessment.identifier, assessmentProject.projectId);
          }
        }

        // Add assessment to projects as needed
        for (const selectedProject of chosenProjects) {
          const index = this.assessmentProjects.findIndex((assessmentProject: Project) => selectedProject.projectId === assessmentProject.projectId);
          if (index === -1) {
            await adminService.addAssessmentToProject(assessment.identifier, selectedProject.projectId);
          }
        }

        await this.getAssessments();
      }
    }
    this.modalButtonsEnabled = true;
  }

  async getAssessments(): Promise<void> {
    this.assessmentsLoading = true;
    await internalAPI
      .getManyAssessments({
        page: this.currentPage,
        page_size: this.perPage,
        sort: this.sort,
        search: this.searchText,
        state: this.stateFilter.join(","),
        organization: this.orgFilter,
        project: this.projectFilter,
      })
      .then((res) => {
        this.assessments = res.data.results;
        this.totalRecords = res.data.totalResults || 0;
      })
      .finally(() => {
        this.assessmentsLoading = false;
      });
  }

  async getOrganizations(): Promise<void> {
    await adminService.getOrganizations().then((res) => {
      this.organizations = res;
    });
  }

  async getProjects(): Promise<void> {
    await adminService.getProjects(null, null, false).then((res) => {
      this.projects = res;
    });
  }

  async getRootBenchmarks(): Promise<void> {
    await internalAPI.getManyBenchmarks({ page_size: 9999, is_root: true }).then((res) => {
      this.rootBenchmarks = res.data.results;
    });
  }

  async getProjectsForAssessment(assessmentId: string): Promise<void> {
    await adminService.getProjects(null, assessmentId, false).then((res) => {
      this.selectedProjects = res;
      this.assessmentProjects = res;
    });
  }

  async regenAssessmentToken(item: Assessment): Promise<void> {
    const token = await adminService.resetAssessmentToken(item.identifier);
    const generatedToken = token;
    const url = this.fullTokenUrl(generatedToken, item.language);
    navigator.clipboard
      .writeText(url)
      .then(() => this.showAlert())
      .catch((err) => console.error(err));
    await this.getAssessments();
  }

  async resetAssessment(item: Assessment): Promise<void> {
    await adminService.resetAssessmentAttempts(item.identifier);
    await this.getAssessments();
  }

  async sendEmail(item: Assessment): Promise<void> {
    adminService
      .sendReportEmailToCandidate(item.identifier)
      .then((res) => {
        if (res.success) {
          alert(`Email successfully sent to ${item.candidate.email}`);
        }
      })
      .catch((err) => console.error(err));
  }

  async recalculateScores(item: Assessment) {
    adminService
      .recalculateAssessmentScores(item.identifier)
      .then(() => {
        alert(`All scoring versions are being recalculated for assessment ${item.identifier.slice(0, 4)}`);
      })
      .catch((err) => console.error(err));
  }

  resetFilters(): void {
    this.stateFilter = [];
    this.orgFilter = "";
    this.projectFilter = "";
  }

  isComplete(item: Assessment): boolean {
    return item.state === "complete" || item.state === "scoring_complete" || item.state === "report-generated";
  }

  fullTokenUrl(generatedToken: string, lang: string | undefined): string {
    let url = window.location.origin + "/?t=" + generatedToken;
    if (lang && lang !== "en") {
      url += "&lang=" + lang;
    }

    return url;
  }

  onSort(ctx: { sortBy: string; sortDesc: boolean }): void {
    let sortText = ctx.sortBy;
    if (ctx.sortDesc) {
      sortText = "-" + sortText;
    }

    this.sort = sortText;
    this.getAssessments().catch((err) => console.error(err));
  }

  async showModal(item: Assessment): Promise<void> {
    this.selectedAssessment = item;
    this.candidateName = item.candidate.name;
    this.candidateEmail = item.candidate.email;

    await this.getProjectsForAssessment(item.identifier);

    this.modalActive = true;
  }

  getStateDescription(state: string): string {
    switch (state) {
      case "incomplete":
        return "The simulation has not been started";
      case "in-progress":
        return "The simulation has been started but not completed";
      case "complete":
      case "scoring_complete":
        return "The simulation has been completed but a report has not been generated";
      case "report-generated":
        return "The simulation has been completed and a report has been generated";
    }
    return state;
  }

  getBenchmarkName(benchmarkId: string): string {
    const benchmark = this.rootBenchmarks.find((b) => b.identifier === benchmarkId);
    return benchmark ? benchmark.name : "";
  }

  closeModal(): void {
    this.selectedAssessment = null;
    this.selectedProjects = [];
    this.modalActive = false;
  }

  countDownChanged(dismissCountDown: number): void {
    this.dismissCountDown = dismissCountDown;
  }

  showAlert(): void {
    this.dismissCountDown = 4;
  }

  bulkFileChanged(event: any): void {
    if (event.target.files) {
      this.bulkCreateFile = event.target.files[0];
    } else {
      this.bulkCreateFile = null;
    }
  }

  bulkGenAssessments(): void {
    if (this.bulkCreateFile) {
      adminService
        .bulkCreateAssessments(this.bulkCreateFile)
        .then((result) => {
          if (result.success) {
            const today = new Date();
            fileDownload(result.detail, `bulk-assessments-${today.getFullYear()}-${today.getMonth()}-${today.getDate()}.csv`);
          } else {
            alert(`Bulk assessment creation failed: ${result.detail}`);
          }
        })
        .finally(() => {
          this.bulkCreateFile = null;
          this.getAssessments().catch((err) => console.error(err));
        })
        .catch((err) => console.error(err));
    }
  }
}
